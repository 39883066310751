<template>
  <div class="menu">
    <a-spin :spinning="loading">
      <a-form ref="form" name="form" :model="formState" @finish="onSearch">
        <a-row>
          <a-form-item label="数据源名称" name="title" class="ui-form__item">
            <a-input v-model:value="formState.name" placeholder="请输入数据源名称"/>
          </a-form-item>

          <a-form-item class="ui-form__item" name="cinemaId" label="所属影院">
            <a-select placeholder="请选择" v-model:value="formState.cinemaId" style="width: 180px;">
              <a-select-option :value="0">全部</a-select-option>
              <a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-row>

        <a-row>
          <a-col :span="18">
<!--            <a-button v-permission="['system_manage_menu_add']" class="a-primaryBtn" type="primary" @click="onAdd">新建</a-button>-->
            <a-button class="a-primaryBtn" type="primary" @click="onAdd">新建</a-button>
          </a-col>
          <a-col :span="6" style="text-align: right;">
            <a-button type="primary" html-type="submit">搜索</a-button>
            <a-button style="margin-left: 20px;" @click="reset">重置</a-button>
          </a-col>
        </a-row>
      </a-form>
      <a-table :dataSource="dataList" :pagination="pagination" :columns="columns" bordered rowKey="id"
               size="middle">
        <template #bodyCell="{ column, record }">
          <template v-if="column.dataIndex === 'ticketSystem'">
            {{ record.ticketSystem == 1 ? '凤凰佳影' : '辰星' }}
          </template>
          <template v-if="column.key === 'operater'">
            <a-button type="line" @click="onDelete(record)">删除</a-button>
          </template>
        </template>
      </a-table>
    </a-spin>
  </div>
</template>

<script>
  // import { dataSourceList, removeDataSource,getDataSource} from '@/service/modules/portPlatform.js';
  import { getCinemaList, getDataSourceList, removeDataSource } from '@/service/modules/cinema.js';

  import {
    Icon
  } from "@/components/icon/icon.js";
  export default {
    components: {
      Icon
    },
    data() {
      return {
        loading: false,
        formState: {
          cinemaId: 0
        },
        searchData: {},
        dataList: [],
        cinemaAllList: [],
        columns: [
          {
            title: "数据源名称",
            dataIndex: "name",
            width: "20%",
          },
          {
            title: "售票系统",
            dataIndex: "ticketSystem",
            width: "20%",
          },
          {
            title: "用户名",
            dataIndex: "username",
            width: "20%",
          },
          {
            title: "密码",
            dataIndex: "password",
            width: "20%",
          },
          {
            title: "链接URL",
            dataIndex: "url",
            width: "20%",
          },
          {
            title: "数据库连接类型",
            dataIndex: "driver",
            width: "20%",
          },
          {
            title: "操作",
            dataIndex: "operater",
            width: "20%",
            key: 'operater'
          },
        ],
        pagination: {
          showSizeChanger: true,
          showQuickJumper: true,
          size: "middle",
          showTotal: (total) => {
            return "共 " + total + " 条";
          },
          total: 0,
          current: 1,
          pageSize: 20,
          onChange: (page) => {
            this.pagination.current = page;
            this.getData();
          },
          onShowSizeChange: (page, pageSize) => {
            this.pagination.current = 1;
            this.pagination.pageSize = pageSize;
            this.getData();
          }
        },
      }
    },
    created() {
      // this.getData();
      this.getAllCinemaList();
      this.getDataSourceList();
    },
    methods: {
      onSearch() {
        this.pagination.current = 1;
        this.searchData = JSON.parse(JSON.stringify(this.formState));
        this.getDataSourceList();
      },
      reset() {
        this.$refs.form.resetFields();
        this.onSearch();
      },
      async getDataSourceList() {
        this.loading = true;
        try {
          const postData = {
            page: this.pagination.current,
            pageSize: this.pagination.pageSize,
            ...this.searchData
          };
          if (postData.cinemaId == 0) delete postData.cinemaId;
          let ret = await getDataSourceList(postData);
          this.loading = false;
          if (ret.code === 200) {
            this.dataList = ret.data.list;
            this.pagination.total = ret.data.totalCount;
            this.list = ret.data.list;
          }
        } catch(e) {
          this.loading = false;
        }
      },
      async getData() {
        this.loading = true;
        try {
          const postData = {
            page: this.pagination.current,
            pageSize: this.pagination.pageSize,
            ...this.searchData
          };
          if (postData.cinemaId == 0) delete postData.cinemaId;
          let ret = await dataSourceList(postData);
          this.loading = false;
          if (ret.code === 200) {
            this.dataList = ret.data.list;
            this.pagination.total = ret.data.totalCount;
            this.list = ret.data.list;
          }
        } catch(e) {
          this.loading = false;
        }
      },
      onAdd() {
        this.$router.push({
          path: '/cinema/basis/dbsource/add'
        });
      },
      onEdit(item) {
        this.$router.push({
          path: '/index/menu/edit',
          query: {
            id: item.id
          }
        });
      },
      onDelete(item) {
        this.$confirm({
          content: '确定删除吗？',
          onOk: () => {
            this.loading = true;
            removeDataSource({
              id: item.id
            }).then(res => {
              this.loading = false;
              if (res.code == 200) {
                this.getDataSourceList();
              }
            })
          }
        })
      },
      async getAllCinemaList(organizationId) {
        this.cinemaAllList = [];
        this.formState.id = 0;
        let ret = await getCinemaList({
          page: 1,
          pageSize: 999999,
          organizationId: organizationId ? organizationId : undefined
        })
        if (ret.code === 200) {
          this.cinemaAllList = ret.data.list;
        }
      },
    }
  };
</script>

<style lang="less" scoped>
  .role {
    // background: red;
    padding: 20px;
  }
  .ui-form__item {
    margin-right: 30px;
  }
  .a-primaryBtn {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    //margin: 20px 0;
    margin-bottom: 20px;
  }
</style>
